<template>
    <div class="contact">
       <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack">联系我们</div>
        <div class="card">
           <!-- <img class="map" src="../../assets/img/ditutu.png" alt=""> -->
           <div class="map" id="dituContent">
               <iframe src="static/ditu.html" width="100%" height="100%" frameborder="0" scrolling="auto"></iframe>
           </div>
           <div class="explain">
               <div class="rows">
                   <div class="flexl">
                       <img src="../../assets/img/address.png" alt="">
                       地址：
                   </div>
                   <p>广西壮族自治区南宁市青秀区滨湖路58号</p>
               </div>
               <!-- <div class="rows">
                   <div class="flexl">
                       <img src="../../assets/img/email.png" alt="">
                       邮箱：
                   </div>
                   <p>chanpin@gzbbsc.com</p>
               </div>
               <div class="rows">
                   <div class="flexl">
                       <img src="../../assets/img/phone.png" alt="">
                       电话：
                   </div>
                   <p>020-855236313/020-85577833</p>
               </div> -->
           </div>
        </div>


    </div>
</template>
<script>
import {useRouter} from 'vue-router';
import {useStore} from 'vuex';
export default {
    setup() {
        let router = useRouter();
        let store = useStore();
        const goBack = () =>{
        if(store.state.isiOS) {
          try {
            window.webkit.messageHandlers.back.postMessage(null);
          } catch (e) {
            alert('跳转返回ios失败');
          }
        }else {
            router.push({
                name: 'User'
            })
        }
        }
        return {
            goBack
        }
    },

}
</script>
<style lang="less" scoped>
.contact {
    padding-top: 12.5vw;    
    .card {
        .map {
            width: 100%;
            height: 50vw;
        }
        .rows {
            display: flex;
            color: #666666;
            font-size: 4vw;
            margin: 2vw 0;
            .flexl {
                flex: 1;
                line-height: 5vw;
                img {
                    width: 4vw;
                    margin-right: 3vw;
                }
            }
            p {
                flex: 3
            }
        }
       
    }
}
</style>